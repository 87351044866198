import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import rules from '@/rules';
import vuetify from "./plugins/vuetify";
import "./plugins/scroll_loader";
import "@babel/polyfill";

Vue.config.productionTip = false;

var axios = require('axios').create({
  baseURL: process.env.NODE_ENV === "production" ? "/api" : "http://localhost:8001",
	withCredentials: true,
});

axios.interceptors.response.use(
  (response: any) => {
    store.commit('updateUser');
    return response;
  },
  (error: any) => {
    if (!error || !error.response){
      return Promise.reject(error);
    }
    let status = error.response.status;
    if (status == 403){
      store.commit('logout');
      router.replace({name: 'login'});
      return new Promise(() => {});
    }
    return Promise.reject(status);
  }
);

Vue.prototype.$axios = axios;
Vue.prototype.$rules = rules;

Vue.use(require('vue-scroll-loader'));

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
