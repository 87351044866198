<template>
  <v-app>
    <v-app-bar app color="primary" dark height="70px">
      <div class="text-h6">
        Centro de Suporte
      </div>
      <v-spacer />
      <v-btn
        v-if="$store.state.logged"
        text
        depressed
        @click="logout()"
      >
        sair
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view :content-height="contentHeight" />
    </v-main>

    <v-snackbar
      v-model="messageSnackbar.showing"
      :color="messageSnackbar.color"
      :timeout="3000"
    >
      <div class="d-flex justify-space-between align-center">
        <span class="font-weight-medium">
          {{ messageSnackbar.message }}
        </span>
        <v-btn text @click="messageSnackbar.showing = false"> ok </v-btn>
      </div>
    </v-snackbar>
  </v-app>
</template>

<script>
import Vue from "vue";

export default {
  name: "App",

  data: () => ({
    messageSnackbar: {
      showing: false,
      message: "",
      color: "",
    },
  }),

  computed: {
    contentHeight(){
      return this.$vuetify.breakpoint.height - 70;
    },
  },

  methods: {
    showMessage(color, msg) {
      this.messageSnackbar.showing = false;
      this.$nextTick(() => {
        this.messageSnackbar.color = color;
        this.messageSnackbar.message = msg;
        this.messageSnackbar.showing = true;
      });
    },
    logout() {
      this.$store.commit("logout");
      this.$router.push({ name: "login" });
    },
  },

  created(){
    Vue.prototype.$showMessage = this.showMessage;
  },

};
</script>
